import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '@components/Layout'
import PageHeaderWrapper from '@components/PageHeaderWrapper'
import CollapseCustom from '@components/CollapseCustom'
import { Heading } from '@components/Typography'
import { ScrollAnimation } from '@components/ScrollAnimation'
import careersContents from '@mocks/career-content.json'

import { DotMap } from "@components/decorator";

import CareersHeaderImage from '@assets/img/header/careers.png'
import { useTranslation } from 'react-i18next'

const CareersPage = () => {
  const data = useStaticQuery(query)
  const { t } = useTranslation()
  const pageTrans = 'career.'

  return (
    <Layout seo={data.strapiHomepage.seo} navbarTheme="light">
      <PageHeaderWrapper
        className="bg-center bg-no-repeat bg-cover relative"
        style={{ backgroundImage: `url(${CareersHeaderImage})` }}
      >
        <div className="bg-blue-900 bg-opacity-80 absolute top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10 text-white flex justify-center items-center flex-col h-[343px] sm:h-[414px] sm:pb-[70px]">
          <Heading h={3} className="sm:text-[48px] sm:pt-0 animate__animated animate__fadeInUp pt-10" style={{ marginBottom: 10 }}>{t('menu.career')}</Heading>
          <p className="animate__animated animate__fadeInUp animate__delay-2s tracking-wider">{t('menu.home')}&nbsp;<span className="inline-block scale-[1.5] font-thin">/</span>&nbsp;{t('menu.career')}</p>
        </div>
      </PageHeaderWrapper>

      <div className="container">
        <div className="sm:pl-[15px] sm:pr-[15px] sm:relative">
          <DotMap color="#28c2a5" className="sm:block sm:top-[50px] sm:right-[-136px] w-[140px] h-[160px] bottom-0 right-[-40px] hidden" style={{ height: 190, width: 140, zIndex: 1 }}/>  
          <Heading
            h={6}
            className=" sm:text-[40px] sm:font-bold sm:mt-[106px] font-extrabold text-[32px] text-blue-700 leading-[45px] mb-6 mt-12 animate__animated animate__fadeInUp  animate__delay-3s"
          >
            {t(`${pageTrans}heading`)}
          </Heading>

          <p className="sm:mt-[34px] text-[18px] animate__animated animate__fadeInUp  animate__delay-4s">
            {t(`${pageTrans}content1`)}
          </p>

          <ScrollAnimation>
            <Heading h={6} className="sm:pt-10 text-[24px] text-navy font-extrabold leading-[45px] mb-6 pt-8">
              {t(`${pageTrans}heading2`)}
            </Heading>
          </ScrollAnimation>

          <div className="sm:mt-[-14px] sm:mb-14 mb-10 mt-[-3px]">
            {careersContents &&
              careersContents.map((career, index) => {
                return (
                  <ScrollAnimation key={index}>
                    <CollapseCustom content={career} index={index} className={'sm:pt-[24px] sm:pb-[24px] pt-[15px] pb-[15px] border-navy-100 ' + (index !== 0 ? "border-t" : "")} />
                  </ScrollAnimation>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default CareersPage
