import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { Heading } from '@components/Typography'
import { Button } from '@components/Button'
import { useTranslation } from 'react-i18next'

import './styles.css'

import tickV from "@assets/img/tick-v.png";

const CollapseCustom = ({ content, className, index }) => {
  const [isOpenedCollapse, setIsOpenedCollapse] = useState(false)
  const { t } = useTranslation()

  const TextIcon = ({
    children,
    icon,
    iconClassName = "",
    className = "",
  }) => {
    return (
      <div
        className={[
          "flex mb-2 sm:px-3",
          className,
        ].join(" ")}
      >
        <img
          className={["mr-5  mt-2", iconClassName].join(" ")}
          src={icon}
          alt="icon"
        />
        <p>
          {children}
        </p>
      </div>
    );
  };

  return (
    <div className={className}>
      <div
        className="sm:hidden flex justify-between items-center overflow-x-hidden pl-[5px]"
        onClick={() => setIsOpenedCollapse(!isOpenedCollapse)}
      >
        <Heading
          h={6}
          className={
            'cursor-pointer transition-all duration-100 ' +
            (isOpenedCollapse ? 'text-[24px]' : 'text-[16px] sm:text-xl ') +
            (isOpenedCollapse ? ' w-full' : '')
          }
        >
          <span className={'font-extrabold text-[24px] inline-block mr-[23px] sm:hidden ' + (isOpenedCollapse ? 'text-blue-700' : 'text-navy-300')}>{index + 1}.</span>
          <span className="text-blue-900 font-bold">{content.title}</span>
        </Heading>
        <div
          className={
            'duration-300 ease-out transition ' + (isOpenedCollapse ? 'translate-x-[150%] hidden' : 'translate-x-[0%]')
          }
        >
          <Button className="sm:w-[170px] sm:h-11 border border-blue-700 text-blue-700 bg-white h-7">
            {t('career.cta1')}
          </Button>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-12 sm:items-center">
        <div className="sm:col-span-1 sm:text-6xl sm:block font-extrabold pl-[13px] hidden">
          <span className={"text-6xl  " + (isOpenedCollapse ? 'text-blue-700' : 'text-gray-100')}>{index + 1}</span>
        </div>
        <div className="sm:col-span-11 ml-[-5px]">
          <div
            className="sm:flex justify-between items-center overflow-x-hidden hidden"
            onClick={() => setIsOpenedCollapse(!isOpenedCollapse)}
          >
            <Heading
              h={6}
              className={
                'cursor-pointer transition-all duration-100 ' +
                (isOpenedCollapse ? 'text-2xl' : 'text-xl sm:text-xl') +
                (isOpenedCollapse && ' w-full mt-[5px]')
              }
            >
              <span className="text-blue-900 font-bold">{content.title}</span>
            </Heading>
            <div
              className={
                'duration-300 ease-out transition ' + (isOpenedCollapse ? 'translate-x-[150%]' : 'translate-x-[0%]')
              }
            >
              <Button className="sm:w-[170px] border border-blue-700 text-blue-700 bg-white w-[75px] h-7 sm:h-11 sm:w-30">
                {t('career.cta1')}
              </Button>
            </div>
          </div>
          <Collapse isOpened={isOpenedCollapse}>
            <div className="sm:pl-0 sm:pt-4 text-[16px] text-navy-400 pl-8 pt-6">
              <p className={'font-extrabold ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                {content.titleDescription}
              </p>

              {
                content.contentDescription && content.contentDescription.map((content, index) => (
                  <p key={index} className={'pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInRight animate__delay-1s')}>
                    {content}
                  </p>
                ))
              }

              {
                content.titleResponsibilities && (
                  <p className={'font-extrabold pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                    {content.titleResponsibilities}
                  </p>
                )
              }

              {
                content.contentResponsibilities && (
                  <div className="pt-3">
                    <ul>
                    {content.contentResponsibilities.map((item, index) => (
                      <li key={index} className="list-none">
                        <TextIcon icon={tickV} iconClassName="w-[15px] h-[10px]">
                          {item}
                        </TextIcon>
                      </li>
                    ))}
                    </ul>
                  </div>
                )
              }

              {
                content.titleRequirements && (
                  <p className={'font-extrabold pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                    {content.titleRequirements}
                  </p>
                )
              }

              {
                content.desRequirements && (
                  <p className={'pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                    {content.desRequirements}
                  </p>
                )
              }

              {
                content.contentRequirements && (
                  <div className="pt-3">
                    <ul>
                    {content.contentRequirements.map((item, index) => (
                      <li key={index} className="list-none">
                        <TextIcon icon={tickV} iconClassName="w-[15px] h-[10px]">
                          {item}
                        </TextIcon>
                      </li>
                    ))}
                    </ul>
                  </div>
                )
              }

              <p className={'pt-3 font-extrabold ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                {content.titelWorkingLocation}: <span className="font-normal">{content.contetnWorkingLocation}</span>
              </p>

              <p className={'pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                {content.timeWork}
              </p>

              <p className={'sm:pt-6 font-extrabold pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInDown')}>
                {content.titleToApply}
              </p>
              <p className={'pt-3 ' + (isOpenedCollapse && 'animate__animated animate__fadeInRight animate__delay-1s')}>
                <span>{content.contentToApply}</span>
                <span className="text-blue-700 px-1">{content.email}</span>
                <span>{content.contentToApplyEnd}</span>
              </p>
            </div>
          </Collapse>

        </div>
      </div>
    </div>
  )
}

export default CollapseCustom
